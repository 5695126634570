/* eslint-disable global-require, import/no-extraneous-dependencies, import/no-mutable-exports */
let history

if (typeof document !== 'undefined') {
  const { createBrowserHistory } = require('history')

  history = createBrowserHistory()
}

export default history
