import React from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner'

function PoweredByKenectScreen(props) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100">
      <Spinner
        size={props.size || 'small'}
        className={props.spinnerClass ?? ''}
      />
    </div>
  )
}

PoweredByKenectScreen.propTypes = {
  size: PropTypes.string,
  spinnerClass: PropTypes.string,
}

export default PoweredByKenectScreen
