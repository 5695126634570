import { useEffect } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { conf } from '../../config'
import { getNestedKey } from '../../utils/dataUtils'

export const gaPageview = (pathname) => ReactGA.pageview(pathname)

function GoogleAnalyticsHandler({ history }) {
  const { host } = window.location
  const subdomain = getNestedKey(host.split('.'), 0)
  const { pathname } = history.location

  useEffect(() => {
    switch (subdomain) {
      case 'pay':
        ReactGA.initialize(conf().gaCode_pay)
        break
      case 'reviews':
        ReactGA.initialize(conf().gaCode_reviews)
        break
      case 'public':
      default:
        ReactGA.initialize(conf().gaCode_public)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => gaPageview(pathname), [pathname])
  return null
}

GoogleAnalyticsHandler.propTypes = {
  history: PropTypes.object,
}

export default GoogleAnalyticsHandler
