import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@kenect-ut/kenect-ui-kit'

function Spinner({ size, className }) {
  return (
    <div className={`text-md-center kenect-text spinner ${className}`}>
      <Loader type="ellipsis" size={size} />
    </div>
  )
}

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'standard', 'large']),
}

export default Spinner
