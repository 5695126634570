class GlobalConfig {
  constructor() {
    this.config = {}
  }

  setConfig = (config) => {
    this.config = config
  }

  getConfig = () => {
    return this.config
  }
}

const globalConfig = new GlobalConfig()

export default globalConfig
