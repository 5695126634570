export const localConfig = {
  paymentServiceUrl: process.env.REACT_APP_PAYMENT_SERVICE_URL,
  locationServiceUrl: process.env.REACT_APP_LOCATION_SERVICE_URL,
  maintenanceMode: process.env.REACT_APP_MAINTENANCE_MODE,
  cardConnectURL: process.env.REACT_APP_CARD_CONNECT_URL,
  reviewServiceUrl: process.env.REACT_APP_REVIEW_SERVICE_URL,

  paymentServiceUrl_V2: process.env.REACT_APP_PAYMENT_SERVICE_URL_V2,
  locationServiceUrl_V2: process.env.REACT_APP_LOCATION_SERVICE_URL_V2,
  locationServiceUrl_V3: process.env.REACT_APP_LOCATION_SERVICE_URL_V3,
  emailServiceUrl_V1: process.env.REACT_APP_EMAIL_SERVICE_URL_V1,

  sunbitKey: process.env.REACT_APP_SUNBIT_KEY,
  kenectEnv: process.env.REACT_APP_KENECT_ENV,

  gaCode_public: process.env.REACT_APP_PUBLIC_GA_CODE,
  gaCode_pay: process.env.REACT_APP_PAY_GA_CODE,
  gaCode_reviews: process.env.REACT_APP_REVIEWS_GA_CODE,
}
