import React from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as loadableRoutes from './loadableRoutes'
import history from '../../history'
import GoogleAnalyticsHandler from './GoogleAnalyticsHandler'

function Routes({ isInMaintenance }) {
  const drawRoutes = () => {
    if (isInMaintenance) {
      return (
        <Switch>
          <Route
            exact
            path="/*"
            component={loadableRoutes.LoadableMaintenanceMode}
          />
        </Switch>
      )
    }
    return (
      <Switch>
        <Route
          path="/unsubscribe/:publicId"
          component={loadableRoutes.LoadableEmailSubscription}
        />
        <Route
          path="/resubscribe/:publicId"
          component={loadableRoutes.LoadableEmailSubscription}
        />
        <Route
          path="/s/:shortCode"
          component={loadableRoutes.LoadableFeedback}
        />
        <Route
          path="/r/:accessCode"
          component={loadableRoutes.LoadableFeedbackV2}
        />
        <Route
          path="/p/:shortCode/receipts/:receiptType"
          component={loadableRoutes.loadablePaymentReceipt}
        />
        <Route
          path="/p/:shortCode"
          component={loadableRoutes.loadablePayment}
        />
        <Route path="*" component={loadableRoutes.LoadableLandingPage} />
      </Switch>
    )
  }
  return (
    <main className="h-100">
      <Router history={history}>{drawRoutes()}</Router>
      <GoogleAnalyticsHandler history={history} />
    </main>
  )
}

Routes.propTypes = {
  isInMaintenance: PropTypes.bool,
}

export default Routes
