import en from '../i18n/en/translation.json'
import es from '../i18n/es/translation.json'
import fr from '../i18n/fr/translation.json'

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
} as const

export default resources
